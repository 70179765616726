import React from "react";
import Helmet from "react-helmet";
import '../styles/home.css';

const Page : React.FunctionComponent = () => {
  const Heading = "Work in progress";

  return (
    <div className="home-container">
      <Helmet>
        <body className="body"/>
        <title> {Heading} </title>
      </Helmet>
      <div className="home-header">
        <h1> {Heading} </h1>
      </div>
    </div>
  )
}

export default Page;